import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from "react-helmet";
import Layout from 'components/Layout';
import BlogPost from 'components/pages/BlogPost';
import { Image } from 'components/pages/BlogPost/styled';
import { ImgGatsbyBackground } from 'components/wrappers';
import styled, { css } from 'styled-components';
import { blogPosts } from 'common/blogPostsFeatured';
import BlogBaseCard from 'components/blog/BaseCard';
import BlogSidebar from 'components/blog/BlogSidebar';
import {PostsWrapper} from 'components/pages/Blog/styled';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import FacebookDefaultIcon from 'images/social-share-icons/facebook-white.png';
import TwitterDefaultIcon from 'images/social-share-icons/twitter-white.png';
import LinkedinDefaultIcon from 'images/social-share-icons/linkedin-white.png';
import BlogOptin from 'components/blog/BlogOptin';
import {Clear, BlogRow, ShareButton, ReadNow, DateText, TitleText, UnorderedList, OrderedList, FirstTitle, BottomShare, SectionFull, OptinSection, ColumnHalf, ColumnHalfLast} from 'components/blog/SingleBlogStyle';

import FacebookStockPriceImgSource from 'images/blog-images/facebook-stock-price-increasing.png';
import FacebookShopImgSource from 'images/blog-images/facebook-shops-shipkoo.png';
import TiktokImgSource from 'images/blog-images/tiktok-to-be-opened.png';
import FacebookShopsImg from 'images/featured-image/facebook-shops-shipkoo.png';




interface OwnProps {
  location: {
    href: string;
  };
}

const BlogArticle = ({ location }: OwnProps) => {
  const data = useStaticQuery(graphql`
    query FacebookShopsImg {
      file(relativePath: { eq: "facebook-shops-shipkoo.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const BackgroundImage = () => <ImgGatsbyBackground fluid={data.file.childImageSharp.fluid} alt="future development of the cross border ecommerce logistics market"/>;
  const { href } = location;
  const intl = useIntl()
  return (
    <Layout>
      <Helmet 
        // title="Facebook Shops and TikTok Continues to be hot in 2020 | Shipkoo"
        title={intl.formatMessage({ id: "blog-post-meta-title-facebook-shops" })}
        htmlAttributes={{
          lang: 'en',
        }}
         meta={
          [
            {
              name: `description`,
              content: "Facebook & Tik Tok are one of the best marketing platforms to be used for 2020. A big business opportunity for eCommerce sellers in growing their businesses.",
            },
            {
              property: `og:title`,
              content: "Facebook Shops and TikTok Continues to be hot in 2020",
            },
            {
              property: `og:description`,
              // content: "Facebook & Tik Tok are one of the best marketing platforms to be used for 2020. A big business opportunity for eCommerce sellers in growing their businesses.",
              content: intl.formatMessage({ id: "blog-post-meta-title-facebook-shops" }),
            },
            {
              property: `og:image`,
              content: FacebookShopsImg,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              name: `twitter:card`,
              content: `summary`,
            },
          ] as JSX.IntrinsicElements['meta'][]
        }
      />
      <BlogPost
        location={location}
        id="facebook-shops-and-tiktok-continues-to-be-hot-in-2020"
        title="Facebook Shops and TikTok Continues to be hot in 2020"
        date="2020-06-04"
        author="Joe Zhang"
        Background={BackgroundImage}
      >
        <BlogRow>
          <BlogSidebar/>
          <div className="column-half last">
            <p>
              Facebook made a big move in May, CEO Zuckerberg announced through live broadcast that Facebook is going to launch it’s own mall platform <a href="https://www.facebook.com/business/shops" target="_blank" rel="noreferrer">- Facebook Shops</a>! One of the main goals of Facebook Shops is to make shopping seamless and empower anyone from a small business owner to a global brand to use our apps to connect with customers. Facebook Shops make it easy for small businesses to set up a single online store for customers to access on both Facebook and Instagram. After this news was released, Facebook's stock price rose to its highest in history.
            </p>

            <Image src={FacebookStockPriceImgSource} className="no-top" alt="facebook stock price increasing"/>

            <p>
              The release of Facebook Shops is equivalent to the announcement of WeChat official shop tools. This is a new window of opportunity for cross-border eCommerce sellers who are familiar with selling products online! Facebook Shops will be gradually opened to users. Some of the invited accounts have already started to take action to try out the new platform and experience the operation in Facebook Shops.
            </p>

            <Image src={FacebookShopImgSource} className="" alt="facebook shops shipkoo"/>
            <p className="small">
              Image Source: <a href="https://about.fb.com/news/2020/05/introducing-facebook-shops/" target="_blank" rel="noreferrer">https://about.fb.com/news/2020/05/introducing-facebook-shops/</a>
            </p>

            <p>
              In addition to Facebook Shops, the hottest discussion in cross-border eCommerce recently is that the overseas version of TikTok may soon add a shopping cart function. TikTok is the largest short-form video platform that contains a majority of teenage users. On this platform, different users create unique videos with music or dialogues to engage a variety of audience.
            </p>

            <p>
              Selling products through TikTok in China has become the myth of eCommerce that the world pays attention to. Adding a shopping cart link to the TiKTok short video can lead to Taobao or TikTok shops. With the great traffic of TikTok, it has gradually become a competitive choice for many eCommerce sellers.
            </p>
            
            <Image src={TiktokImgSource} alt="tiktok to be opened this year" />

            <p>
              On TikTok, the shopping cart function has not yet been opened, but it is generally expected to be opened this year. There are already many KOL opened accounts on TikTok. These celebrities put Amazon store links in their TikTok account, open social media platforms such as Instagram, Facebook, and YouTube. They publish various short videos every day to guide fans to their connected platforms for placing orders.
            </p>

            <p>
              The core of the cross-border eCommerce seller business is to create high-quality products while constantly finding lower cost for traffic operation.
            </p>

            <p>
              TikTok and Facebook Shops are currently in a dividend period. In particular, cross-border eCommerce sellers should not miss this big business opportunity for growing online businesses.
            </p>
            
            <BottomShare>
              <div className="column-half">
                <p>Share the article</p>
              </div>
              <div className="column-half last">
                <FacebookShareButton url={href}>
                  <ShareButton><img src={FacebookDefaultIcon} alt="FacebookDefaultIcon" /> <span>Share</span></ShareButton>
                </FacebookShareButton>
                <TwitterShareButton url={href}>
                  <ShareButton><img src={TwitterDefaultIcon} alt="TwitterDefaultIcon" /> <span>Tweet</span></ShareButton>
                </TwitterShareButton>
                <LinkedinShareButton url={href}>
                  <ShareButton><img src={LinkedinDefaultIcon} alt="FacebookDefaultIcon" /> <span>Share</span></ShareButton>
                </LinkedinShareButton>
              </div>
              <Clear></Clear>
            </BottomShare> 
          </div>  
          <Clear></Clear>
       </BlogRow> 
      </BlogPost>
      <SectionFull>
        <BlogRow>
          <h2>More From Shipkoo</h2>
          <PostsWrapper>
            {blogPosts.map(post => (
              <BlogBaseCard key={`${post.title}-${post.id}`} post={post} />
            ))}
          </PostsWrapper>
        </BlogRow>
      </SectionFull>
      <BlogOptin />
    </Layout>
  );
};

export default BlogArticle;